import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse, Navbar, NavbarToggler, Nav, UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import UserPhotoJpeg from '../Assets/user-photo.jpeg';
import Session from '../../services/Session';
//import { runNotify } from '../../services/SocketClient';
import { ListNotifications } from '../../services/Api';
import { withSnackbar } from 'notistack';
import { IconBell, IconBellFilled, IconLogout, IconUser } from '@tabler/icons-react';


class NavBarTop extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            username: Session.getInfoUser().username,
            full_name: Session.getInfoUser().fullName,
            profile: Session.getTypeProfileStrignUser(),
            photo: UserPhotoJpeg,
            homeUrl: '/',
            notificaciones:[],
            countNotifications:0,
        };
        this.toggle = this.toggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async componentDidMount() {
        this.notifications();
    }

    toggle() {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen,
        });
    }
    async notifications(){
        const list = await ListNotifications();
        this.setState({
            countNotifications: list.not_read,
            notificaciones: list.data
        });
    }
    handleClose(event) {
       
        const { homeUrl } = this.state;
        event.preventDefault();
        Session.close();
        window.location.href = homeUrl;
    }
    render() {
        const {
            username, profile,  photo, isOpen, full_name,
            notificaciones,countNotifications,
        } = this.state;
        let shortFull_name = 'Sin nombre';
        try {
            shortFull_name = full_name.length > 20 ? `${full_name.substring(0, 20)}...` : full_name;
        } catch (error) {
            console.error(error);
        }
        //runNotify(this);
        return (
                <Navbar className="fixed-top nav-bar-top" expand="md">
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <div className="col mr-5 py-4 text-white">
                            <span>v0.2</span>
                        </div>
                        <UncontrolledDropdown nav innavba="false">
                            <DropdownToggle nav caret className="user-dropdown-toggle notifications-top" data-count={`${countNotifications ? countNotifications : 0}`}>
                                <IconBellFilled color="white" size={24} strokeWidth={1.5} />
                            </DropdownToggle>
                            <DropdownMenu right className="user-dropdown-top text-center shadow-sm dropdown-20rem">
                                <DropdownItem header className="font-weight-bold d-flex justify-content-between px-0">
                                    <div className="col mr-5">
                                        <strong>Notificaciones</strong>
                                    </div>
                                    <div className="col ml-5">
                                        <Link to="/notificaciones" className="small">Ver todas</Link>
                                    </div>
                                </DropdownItem>
                                {
                                    notificaciones && notificaciones.length > 0 ? notificaciones.map(((t, i) => {
                                        const { sender, short_description, read } = t;
                                        const notRead= read ?  '' : 'not-read';
                                        return (
                                            <div key={`dropdown-key-${i}`}>
                                                <DropdownItem divider />
                                                <DropdownItem className="font-weight-bold d-flex justify-content-between px-0">
                                                    <div className={`col-10 pl-2 ${notRead}`}>
                                                        <p className="small text-left break-word">
                                                            <strong>{sender && sender.full_name ? sender.full_name : ''}</strong>
                                                            <br/>
                                                            {`${short_description}`}
                                                        </p>
                                                    </div>
                                                </DropdownItem>
                                            </div>
                                        );
                                    })) : <><DropdownItem divider /> <DropdownItem className="font-weight-bold px-0">Sin notificaciones</DropdownItem></>
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav innavba="false">
                            <DropdownToggle nav caret className="user-dropdown-toggle">
                                <div className="user-info m-0 text-center">
                                    <small className="user-name">
                                        <strong>
                                            {shortFull_name}
                                        </strong>
                                    </small>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className="user-dropdown-top">
                                <Link to="/mi/perfil">
                                    <DropdownItem>
                                        <IconUser size={18} />
                                        <span>Ir a perfil</span>
                                    </DropdownItem>
                                </Link>
                                <DropdownItem divider />
                                <DropdownItem onClick={this.handleClose} className="user-logout">
                                  <IconLogout size={18}/>

                                    <span>Cerrar Sesión</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}
export default withSnackbar(NavBarTop);