
import React, { Component } from 'react';
import biin_logo from '../../assets/img/biin/Logotipo-01.png';

class LayoutAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { children } = this.props;
        return (
            <div className="row justify-content-center align-items-center h-100">
                <img alt="logo" src={biin_logo} className="mb-3 b4ind-logo" />
                <div className="blue" />
                <div className="col-11 col-sm-6 col-md-4 col-lg-3 rounded py-5 bg-white shadow-lg">
                    {children}
                </div>
            </div>
        );
    }
}
export default LayoutAuth;
