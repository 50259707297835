import React, { Component } from 'react';
import { translate } from 'react-switch-lang';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

import iconlogin from '../../assets/icon/icon-login.png';
import AlertSystem from '../../layout/AlertSystem';
import { SignIn as SignInApi } from '../../services/Api';
import Session from '../../services/Session';
import FormProcessing from '../../services/FormProcessing';
import LayoutAuth from './LayoutAuth';
import { IconLock, IconMail, IconPassword } from '@tabler/icons-react';

// import Input from '../../components/Input/Input';
// import Button from '../../components/Button/Button';
// import {
//     CardLogin, BoxLogo, InicioSec, CardCenter, Icon, SLink, BackGround,
// } from '../../styles/Login/SignIn';


class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            loading: false,
            message: 'Componente SignIn',
            type: 'warning',
        };
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        const data = await FormProcessing.toObject(this.form.current);
        let validate = [];
        const response = await SignInApi(data);
        if (response.enable_validations === true) {
            validate = response;
        }
        FormProcessing.validate(this.form.current, validate);
        if (response.error) {
            this.setState({
                showAlert: true,
                loading: false,
                message: response.error,
                type: 'danger',
            });
        } else if (response.data && response.data.authorization) {
            Session.open(response.data);
            this.setState({
                loading: false,
            });
            window.location.href = '/';
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        const {
            showAlert,
            message,
            type,
            loading,
        } = this.state;
        const BtnSpinner = () => {
            const style = {
                top: '-2px',
                position: 'relative',
                right: '9px',
            };
            return (
                <>
                    <Spinner
                        size="sm"
                        color="white"
                        style={style}
                    />
                    Accediendo..
                </>
            );
        };
        return (
            <LayoutAuth>
                <p className="text-center">
                    <img alt="icon-user" src={iconlogin} width="50" className="mb-2" />
                </p>
                <h3 className="text-center mb-0 text-black mb-4">Inicio de Sesión</h3>
                <form onSubmit={(e) => this.handleSubmit(e)} ref={this.form}>
                    <div className="form-group">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text bg-white"
                                    id="correo-input1"
                                >
                                    <IconMail />
                                </span>
                            </div>
                            <input
                                type="text"
                                name="email"
                                className="form-control border-left-0"
                                placeholder="Ingresa tu correo"
                                aria-label="Correo"
                                aria-describedby="correo-input1"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text bg-white"
                                    id="correo-input1"
                                >
                                    <IconLock />
                                </span>
                            </div>
                            <input
                                type="password"
                                name="password"
                                className="form-control border-left-0"
                                placeholder="Ingresa tu clave"
                                aria-label="Calve"
                                aria-describedby="Calve-input1"
                            />
                        </div>
                    </div>
                    <div className="form-group text-center pt-3">
                        <button
                            type="submit"
                            className="btn btn-black"
                            disabled={loading}
                        >
                            { loading ? <BtnSpinner /> : 'Acceder' }
                        </button>
                    </div>
                </form>
            </LayoutAuth>
        );
    }
}
export default translate(SignIn);
