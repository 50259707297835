import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Session from '../../services/Session';
import {IconFileInvoice, IconHome2, IconUsers} from '@tabler/icons-react'
import { NavLink } from '@mantine/core';

export default class SiedebarLeft extends Component {
    constructor(props) {
        super(props);
        let openS = 'open';
        if ('sidebarOpen' in localStorage) {
            openS = localStorage.getItem('sidebarOpen');
        }
        this.state = {
            open: openS,
            close: false,
            collapse: window.location.pathname.split('/')[1],
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.toggleList = this.toggleList.bind(this);
    }

    handleClick() {
        const { open } = this.state;
        const { sidebarClose } = this.props;
        const openS = open === 'open' ? 'close' : 'open';
        localStorage.setItem('sidebarOpen', openS);
        this.setState({
            open: openS,
        });
        sidebarClose();
    }

    handleClose(event) {
        const { close } = this.state;
        event.preventDefault();
        Session.close();
        this.setState({
            close: !close,
        });
        window.location.href = '/';
    }

    toggleList(e, l) {
        // console.log(history)
        let link = l;
        const { collapse } = this.state;
        if (collapse === link) {
            link = false;
        }
        e.preventDefault();
        this.setState({ collapse: link });
    }

    render() {
        const { open, collapse } = this.state;

        const Menu = () => {
            return (
                <>
                    <NavLink
                        href="/"
                        label="Inicio"
                        //TODO: Probar Themeicon: https://mantine.dev/core/theme-icon/
                        leftSection={<IconHome2  size="1.2rem"  />}
                    />
                    <NavLink
                        href="/usuario/lista"
                        label="Usuarios"
                        leftSection={<IconUsers size="1.2rem" />}
                    />
                    <NavLink
                        label="Boletas"
                        leftSection={<IconFileInvoice size="1.2rem"/>}
                    >
                        <NavLink href="/bills/" label="Boletas BTE" />
                        <NavLink href="/observed-bills/" label="Boletas Observadas"/>
                    </NavLink>
                    <NavLink href="/takedata-log/" label="Log Takedata" leftSection={<IconFileInvoice size="1.2rem"/>}/>
                </>
            );
        };

        const brand = require ('../../assets/img/biin/Logotipo- negativo-01.png');
        return (
            <div className={open === 'open' ? 'sidebar-left' : 'sidebar-left sidebar-close'}>
                <ul className="p-0">
                    <div className="top-sidebar" />
                    <li className="frist-brand">
                        <img alt="Icon" className="img-fluid icon-brand-open" src={brand} />
                    </li>
                    <Menu />
                </ul>
            </div>
        );
    }
}

SiedebarLeft.propTypes = {
    sidebarClose: PropTypes.func.isRequired,
};
