import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  TFoot,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  Pagination,
  Grid,
  ActionIcon,
  Modal,
  Card,
  Box,
  Select,
  TableTfoot,
  CloseButton,
  Loader,
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconEye} from '@tabler/icons-react';
import classes from './table-sort.scss';
import { useDisclosure } from '@mantine/hooks';
import { Amount, formatRut } from '../../../services/Utils';


const Th = ({ children, reversed, sorted, onSort }) => {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
};



const TableSort = ({ paginatedData, headers, onPageChange, onPageSizeChange, isLoading, onSearch, onSort }) => {
    console.log(paginatedData);
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(paginatedData.data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(paginatedData.current_page);
  const [pageSize, setPageSize] = useState(paginatedData.per_page);

  useEffect(() => {
    setSortedData(paginatedData.data);
    setPage(paginatedData.current_page);
  }, [paginatedData]);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    onSort(field, reversed);
  };

  const handleSearch = () => {
    onSearch(search);
  };

  const handlePageChange = (page) => {
    setPage(page);
    onPageChange(page);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    onPageSizeChange(value);
  };

  const handleClearSearch = () => {
    setSearch('');
    onSearch('');
  };

  const getNestedValue = (obj, path) => {
    //obtiene un valor anidado de un objeto, utiliza un string con puntos para acceder a los valores
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const viewDetailModal = (bill,service) => {
    bill = { bill, service: {...service}}
    setSelectedBill(bill);
    open();
  }

const rows = sortedData.map((row) => {
  // Obtenemos el valor de notification_type para definir el estilo de la fila
  const notif = getNestedValue(row, 'notification_type');
  const rowStyle = {};
  if (notif === 'warning') {
    rowStyle.backgroundColor = '#ffffe0'; // amarillo pálido
  } else if (notif === 'error') {
    rowStyle.backgroundColor = '#f8d7da'; // rojo pálido
  }

  // Filtramos el header para excluir 'notification_type'
  const filteredHeaders = headers.filter(
    (header) => header.attribute !== 'notification_type'
  );

  return (
    <Table.Tr key={row.id} style={rowStyle}>
      {filteredHeaders.map((header) =>
        header.parseMethod ? (
          <Table.Td key={header.attribute}>
            {header.parseMethod(getNestedValue(row, header.attribute))}
          </Table.Td>
        ) : (
          <Table.Td key={header.attribute}>
            {getNestedValue(row, header.attribute)}
          </Table.Td>
        )
      )}
    </Table.Tr>
  );
});

  return (
    <>
    <Card shadow="sm" padding="lg" radius="md" withBorde>
      <>
          <Group justify="flex-end">        
                <TextInput
                  placeholder="Buscar según cualquier campo"
                  mb="md"
                  size='xs'
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                  rightSection={
                    <CloseButton
                      aria-label="Clear input"
                      onClick={() => handleClearSearch()}
                      style={{ display: search ? undefined : 'none' }}
                    />
                  }
                />
                <Button mb="md" size='xs' color='#534394' onClick={handleSearch}>
                  Buscar   
                </Button>
          </Group>
          <ScrollArea>
            <Table highlightOnHover verticalSpacing="xs" miw={600} layout="fixed">
              <Table.Thead>
                <Table.Tr>
                  {headers.map((header) => (
                    <Th
                      className
                      key={header.attribute}
                      sorted={sortBy === header.attribute}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting(header.attribute)}
                    >
                      {header.label}
                    </Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              { isLoading  ? 
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td colSpan={headers.length+1}>
                    <Center py={30}><Loader color='#534394'/></Center>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody> : 
              <Table.Tbody>
                {rows.length > 0 ? (
                  <>
                    {rows}
                  </>
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={headers.length+1}>
                      <Text fw={500} ta="center">
                        No se encontraron resultados
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
              }       
            </Table>
            { (isLoading  || paginatedData.total == 0 )? "" :
            <Group m={0} justify="space-between">   
                  <Text size='xs'> {paginatedData.from} - {paginatedData.to} / {paginatedData.total} </Text>
                  <Pagination
                        total={paginatedData.last_page}
                        value={page}
                        onChange={handlePageChange}
                        m="xl"
                        color='#534394'
                        
                      />
                      <Group>
                        <Text size='xs'>Elementos por página</Text>
                        <Select
                          size="xs"
                          value={pageSize.toString()}
                          data={['10', '25', '50', '100']}
                          onChange={handlePageSizeChange}
                          maw={70}
                        />
                      </Group>
                    
            </Group>
            }
          </ScrollArea>
      </>
    </Card>
  </>
  );
};

export default TableSort;
