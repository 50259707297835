import React, {useEffect, useState} from 'react';
import { Badge, Button, Grid, Loader, Select, Text } from '@mantine/core';
import './Index.scss';
import { IconAlertTriangle, IconBrandTelegram, IconInbox, IconFileDownload } from '@tabler/icons-react';
import DashboardCard from './components/dashboard-card';
import TableSort from './components/table-sort';
import useBills from './hooks/useBills';
import { formatDate, formatRut } from '../../services/Utils';
import useHoldings from './hooks/useHoldings';
import useInstitutions from './hooks/useInstitutions';
import DashboardCardDoble from './components/dashboard-card-doble';
import Session from '../../services/Session';
import axios from 'axios';

const BillsIndex = () => {
    const [page, setPage] = useState(1);  
    const [pageSize, setPageSize] = useState(10);  
    const [sortBy, setSortBy] = useState('');
    const [search, setSearch] = useState('');	  
    const [reversed, setReversed] = useState('desc');  
    const [holdingSelection, setHoldingSelection] = useState('');
    const [institutionSelection, setInstitutionSelection] = useState('');
    const [periodSelection, setPeriodSelection] = useState('2025');
    const [exportLoading, setExportLoading] = useState(false);
    const [daysFilter, setDaysFilter] = useState('');

    const { getBillsIndex } = useBills();
    const { 
        paginatedData: paginatedDataIndex, 
        resume: resumeIndex,
        isLoading: isLoadingIndex, 
        isValidatingIndex,
        isError: isErrorIndex,
        mutate :  mutateBills,
    } = getBillsIndex(page, pageSize, sortBy, reversed, search, holdingSelection, institutionSelection, periodSelection, daysFilter);

    const {getHoldingIndex} = useHoldings();
    const { holdings, errorHoldings,isLoadingHoldings } = getHoldingIndex();

    const {getInstitutionsIndex} = useInstitutions();
    const { institutions, errorInstitutions,isLoadingInstitutions, mutateInstitutions } = getInstitutionsIndex(holdingSelection.id);

    const periodData = ['2025','2024', '2023', '2022', '2021'];
   
    const getStatusPill = (status) => {
        const statusColors = {
            'Emitida': '#17C671',
            'Emviada 1D': '#FFC107',
            'Enviada 2D+': '#c91a25',
            'Enviada': '#17C671',
        };
        return <Badge color={statusColors[status]} >{status}</Badge>;
    };

    const headers = [
        {                                       label: 'Folio',             attribute: 'bill_takedata.folio'},
        { parseMethod : (s) => s.toUpperCase(), label: 'Nombre Emisor',     attribute: 'service.professional.full_name'},
        { parseMethod : formatRut,              label: 'Rut Emisor',        attribute: 'service.professional.rut'},
        {                                       label: 'Nombre Receptor',   attribute: 'institution.name'},
        { parseMethod : formatDate,             label: 'Fecha Envío',       attribute: 'emited_at'},
        { parseMethod : formatDate,             label: 'Fecha Recibida',    attribute: 'received_at'},
        {                                       label: 'Días',              attribute: 'days_passed'},
        { parseMethod : getStatusPill,          label: 'Estado',            attribute: 'custom_status'}
    ];   

    const handleCardClick = (type) => () => {
        if (type === '1-day') {
            daysFilter == '1' ? setDaysFilter('') : setDaysFilter('1')
        } else if (type === '2-day') {
            daysFilter == '2' ? setDaysFilter('') : setDaysFilter('2')
        }
    };

    const handlePageChange =  (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
    }

    const handleSearch = (search) => {
        console.log(search);
        setSearch(search);
    }

    const handleSort = (sortBy, reversed) => {
        console.log(sortBy, reversed);
        setSortBy(sortBy);
        reversed ?  setReversed("asc") : setReversed("desc");
    }

    const handleExport = async () => {
        const BACK_URL = process.env.REACT_APP_API_URL;
        setExportLoading(true);
        await axios({
			method: 'post',
			url: BACK_URL+'superadmin/bteLog/export', 
			headers: {
				'Accept': 'application/vnd.ms-excel',
				'Authorization': Session.getTokenUser(),
				'Token-Api': 'BACK_TOKEN',
				'Language': 'es',
				'Token-User': Session.getTokenUser(),
				'Status-User': Session.getStatusUser(),
				'Type-Profile-User': Session.getTypeProfileUser(),
			},
            data: {
                holding_id: holdingSelection.id,
                institution_id: institutionSelection.id,
                period: periodSelection ? periodSelection : '',
                daysFilter: daysFilter ? daysFilter : ''
            },
			responseType: 'arraybuffer'
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
            var fileName = 'BTE';
            if(holdingSelection.name){
                fileName+='-'+holdingSelection.name;
            }
            if(institutionSelection.name){
                fileName+='-'+institutionSelection.name;
            }
            if(periodSelection){
                fileName+='-'+periodSelection;
            }
            fileName+='('+(new Date).toLocaleDateString()+').xlsx';
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(url);
            setExportLoading(false);
		})
		.catch((error) => {
            console.log(error)
            setExportLoading(false);
        });

    }

    useEffect(() => {
        mutateBills();
    }, [page, pageSize, sortBy, reversed, search, institutionSelection, periodSelection,daysFilter]);

    useEffect( ()  => {
        setInstitutionSelection({value: null});
        mutateInstitutions();
    }, [holdingSelection]);


    return (
        <>
            { (isLoadingIndex  || isLoadingHoldings) ? 'Cargando...' : 
            (
            <>
                <Grid my="lg" align='flex-end'>
                    <Grid.Col span={'auto'}>
                        <Text size={'34px'}>Boletas BTE</Text>
                    </Grid.Col>
                    <Grid.Col span={'content'}>
                        {exportLoading ?
                         (<Button size='sm' color='#534394' leftSection={<Loader size={16} color="white"/>} >Generando</Button>)
                        :
                        (<Button size='sm' color='#534394' onClick={event => handleExport(event)} leftSection={<IconFileDownload size={20} />}>Descargar</Button>)    
                        }
                    </Grid.Col>
                </Grid>
                <Grid align='flex-end'>
                    <Grid.Col span={'auto'}>
                        <Select
                            label="Holding"
                            placeholder="Seleccione una opción"
                            data= { holdings.map(item => ({...item, value: item.id.toString(), label: item.name})) }
                            value={holdingSelection._value}
                            onChange={(_value, option) => setHoldingSelection(option || '')}
                        />
                    </Grid.Col>
                    <Grid.Col span={'auto'}>
                        <Select
                            label="Institución"
                            placeholder="Seleccione una opción"
                            data={ institutions.map(item => ({...item, value: item.id.toString(), label: item.name})) }
                            value={institutionSelection.value}
                            disabled = { (holdingSelection === '')}
                            onChange={(_value, option) => setInstitutionSelection(option || '')}
                        />
                    </Grid.Col>
                    <Grid.Col span={'auto'}>
                        <Select
                            label="Periodo"
                            placeholder="Seleccione una opción"
                            defaultValue={'2025'}
                            data={periodData}
                            value={periodSelection}
                            onChange={setPeriodSelection}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={4}>
                        <DashboardCardDoble
                            firstTitle="Solicitadas"
                            firstNumber={resumeIndex?.emited}
                            secondTitle={'Emitidas'}
                            secondNumber={resumeIndex?.received}
                            icon={<IconBrandTelegram size='40' color='#95b734'/>}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <DashboardCard
                            title="Solicitadas hace 1 día"
                            number={resumeIndex?.non_received_1}
                            onClick={ handleCardClick('1-day')}
                            icon={<IconInbox size='40' color='#FFA903'/>}
                            primaryColor={'#FFFFFF'}
                            secondaryColor={'#FFA903'}
                            active = {daysFilter == '1'}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <DashboardCard
                            title="Solicitadas hace 2 o más días"
                            number={resumeIndex?.non_received_2}
                            onClick={ handleCardClick('2-day') }
                            primaryColor={'#FFFFFF'}
                            secondaryColor={'#C91A25'}
                            icon={<IconAlertTriangle size='40'color='#C91A25'/>}
                            active = {daysFilter == '2'}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mt={5}>
                    <Grid.Col>
                        {   isLoadingIndex ? 
                                'Cargando...' : 
                            isErrorIndex ? 
                                'Error al cargar los datos' :  
                            <TableSort paginatedData={paginatedDataIndex} headers={headers} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} isLoading ={isValidatingIndex} onSearch={handleSearch} onSort={handleSort}/>
                        } 
                    </Grid.Col>
                                                
                </Grid>
            </>)}
        </>
    );
};

export default BillsIndex;